<template>
    <div>
        <span v-if="answer === null">
            <icon type="far" class="fa-xl" icon="square"/>
        </span>
        <span v-else>
            <icon type="far" class="fa-xl" icon="check-square"/>
        </span>
    </div>
</template>
<script>
export default {

    props: {
        answer: {
            type: [String, Number]
        },
        builder: {
            type: Object,
            default: () => ({})
        }
    },

}
</script>